@import "../_app.scss";

@import '../homepage.scss';

/**/

#heroBanner {
  background: image-url("_yesbackup/homepage/heroBanner.png");
  min-height: 450px;

  .text {
    width: 60%;
    float: left;
  }

  h1 {
    padding: 80px 0 0;
    color: #fff;
    font-size: 45px;
  }

  ul {
    margin: 60px 0 0;
    list-style: none;

    li {
      line-height: normal;
      font-family: calibri, arial, sans-serif;
      font-size: 23px;
      margin-bottom: 12px;
      display: block;
      color: #fff;
    }
  }
}

/**/

/**/

#content {
  padding-bottom: 0;
}

/**/

#heroBanner #signup {
  width: 35%;
  background: #fff;
  float: right;
  position: relative;
  top: -30px;
  border-radius: 5px;

  form {
    padding: 15px;
    background: #fff;
    width: auto;
    border-radius: 5px;
  }

  .inputWrapper {
    width: 100%;
    float: left;
    margin: 0 0 20px 0;
    position: relative;
  }

  .push {
    width: 100%;
  }

  .btn {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 10px;
  }
}

/**/

/**/

/**/

#signup input {
  &[type="text"], &[type="password"], &[type="email"] {
    width: 95%;
    background: #f9faf9;
  }
}

/**/

/**/

#content .overview {
  width: 980px;
  padding: 20px 0 0;
  float: left;

  .overflow {
    width: 1060px;
    float: left;
  }

  p {
    width: 380px;
    padding: 0 0 0 80px;
    float: left;
    margin: 0 60px 50px 0;
    position: relative;

    [class*="icon"] {
      position: absolute;
      top: 0;
      left: 0;
    }

    strong {
      display: block;
    }
  }
}

.steps {
  width: 100%;
  padding: 0 0 40px;
  float: left;
  overflow: hidden;
  margin: 0 0 30px;
  position: relative;

  ol {
    width: 1100px;
    margin: 0;
    list-style: none;
  }

  li {
    width: 250px;
    padding: 50px 75px 0 0;
    float: left;
    margin: 0 30px 0 0;
    position: relative;

    span {
      display: block;
      overflow: hidden;
    }

    .text {
      width: 250px;
      text-align: center;
      font-size: 19px;
      margin-top: 30px;
    }

    p {
      text-align: center;
      margin-top: 10px;
    }

    i {
      margin: 0 auto;
      display: block;
    }
  }
}

/**/

.carousel {
  overflow: hidden;
  position: relative;
  margin-bottom: 70px;

  .controls > * > * {
    width: 53px;
    height: 39px;
  }

  #prevBtn > * {
    background-position: 0px -240px;
  }

  .controls {
    > * > * {
      display: block;
      overflow: hidden;
      text-indent: -9999em;
      background: image-url('_yesbackup/iconsmedium.png') 0px -300px no-repeat;
      height: 60px;
      width: 60px;
    }

    #nextBtn {
      right: 0;
    }
  }

  [class^=panel] {
    position: relative;
    width: 1080px;
  }

  .controls > * {
    position: absolute;
    top: 40%;
    left: auto;
  }

  ul, ol {
    float: left;
    overflow: hidden;
    margin: 0;
    list-style: none;
  }

  ul li {
    padding: 0;
    float: left;
  }
}

#content .carousel .viewport {
  width: 100%;
  height: auto !important;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
}
